<template>
  <div>
    <b-dropdown :text="causeOfReductionText">
      <b-dropdown-item v-for="option in causesOfReduction" :key="option.value" :value="option.value"
        @click="handleDropdownSelection(option.value, option.text)">
        {{ option.text }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>


export default {
  name: 'CauseOfReduction',
  props: {

  },
  created() {
    this.getCausesOfReduction()
  },
  data() {
    return {
      causesOfReduction: [],
      selectedCauseOfReduction: null,
      causeOfReductionText: this.$t("general.dropdownText"),
    }
  },
  methods: {
    handleDropdownSelection(cause, text) {
      this.selectedCauseOfReduction = cause
      this.causeOfReductionText = text
      this.$emit('selectedCauseOfReduction', this.selectedCauseOfReduction);
    },
    emitSelectedCauseOfReduction() {
      this.$emit('selectedCauseOfReduction', this.selectedCauseOfReduction);
    },
    getCausesOfReduction() {
      const causesOfReduction = {
        broken: this.$t("detailPanel.reduceModal.causes.broken"),
        incorrect: this.$t("detailPanel.reduceModal.causes.incorrect"),
        corked: this.$t("detailPanel.reduceModal.causes.corked"),
        private_drawings: this.$t("detailPanel.reduceModal.causes.private_drawings"),
        stolen: this.$t("detailPanel.reduceModal.causes.stolen"),
        other: this.$t("detailPanel.reduceModal.causes.other")
      };
      Object.entries(causesOfReduction).forEach(([key, value]) => {
        this.causesOfReduction.push({ value: key, text: value });
      });
    }
  },
}

</script>